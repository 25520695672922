.full-page.section-image {
  background: url('./assets/img/athelete.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: static;
  min-height: 690px;
  margin-top: -5px;
}

.section-image {
  width: 100%;
}
.navbar {
  margin-bottom: 0px !important;
}

.wrapper.wrapper-full-page {
  /* min-height: 0vh !important; */
  height: auto;
}

.modal-header {
  font-weight: 300;
  font-size: 1.15rem;
}
.fa {
  font-size: 25px !important;
  width: 40px;
}
.fa-twitter {
  color: #55acee !important;
}

.fa-dribbble {
  color: #ea4c89 !important;
}
.fa-github {
  color: black !important;
}
.fa-facebook {
  color: #3b5998 !important;
}

.modal-backdrop {
  position: relative !important ;
  height: 0vh !important;
}

#my {
  width: 100%;
}

.sidebar .sidebar-wrapper {
  background-color: white !important;
}
.sidebar .nav li > a {
  color: black !important;
}
.sidebar .nav li:hover:not(.active) > a,
i {
  color: white;
}
.sidebar .nav li.active > a:not([data-toggle='collapse']),
.off-canvas-sidebar .nav li.active > a:not([data-toggle='collapse']) {
  background-color: #ff3636;
}
.now-ui-icons {
  color: black !important;
}
.logo {
  background-color: white !important;
}
.sidenavimg {
  margin-left: 40px;
  padding: 10px !important;
}
.now-ui-icons media-2_sound-wave {
  color: #ff3636 !important;
}
.panel-header {
  background: #1e3149 !important ;
}
.main-panel > .content {
  width: 150% !important;
}
i.now-ui-icons {
  color: black !important;
}
#usericon {
  color: white !important;
}
.main-panel {
  overflow: hidden;
}

@import url(https://fonts.googleapis.com/css?family=Ubuntu:400,500,700,300);
@import url(https://fonts.googleapis.com/css?family=Lobster);
*,
*:before,
*:after {
  padding: 0;
  margin: 0;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  color: #fff;
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande',
    sans-serif;
  font-weight: 300;
}

body,
html {
  width: 100%;
  height: 100%;
}

h1 {
  font-family: 'Lobster', cursive;
  font-weight: 300;
  font-size: 2.5em;
}

.sidebar-btn {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

label[for='sidebar-btn'] {
  position: fixed;
  left: 15px;
  top: 15px;
  z-index: 2;
  height: 30px;
  width: 30px;
  cursor: pointer;

  background-size: contain;
}

.sidebar-btn + label,
.content {
  transition: left 0.1s;
}

.sidebar-btn:checked + label {
  left: 245px;
}

.sidebar-btn:checked ~ .content {
  left: 230px;
}

.sidebar {
  list-style-type: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-color: #c0392b;
}

.sidebar li {
  background-color: #c0392b;
  padding: 1em;
  color: #fff;
  width: 300px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  -moz-transition-property: all;
  -o-transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -moz-transition-duration: 50ms;
  -o-transition-duration: 50ms;
  -webkit-transition-duration: 50ms;
  transition-duration: 50ms;
  -moz-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.sidebar li:hover {
  background-color: #962d22;
}

.content {
  min-height: 100%;
  min-width: 100%;
  background-color: #34495e;
  position: relative;
  top: 0;
  bottom: 100%;
  left: 0;
  z-index: 1;
  padding: 2em;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

.main-panel > .content {
  width: 150% !important;
}

/*Icon Style*/
.container-fluid {
  padding-top: 24px;
}
#social {
  float: right;
  padding: 1px;
}
#social ul {
  margin-top: 8px;
}
#social ul a:hover {
  opacity: 0.8;
}
.svg-inline--fa.fa-w-16 {
  width: 3em;
  font-size: 20px;
}

/* Login and Register Modal Dialog Box Style*/
#loginmodal .modal-dialog {
  padding-top: 150px;
}
#loginmodal .modal-header {
  margin: 0 auto;
}
#loginmodal h5 {
  text-align: center;
}
#loginmodal .btn {
  display: block;
  margin: 10px auto;
}
#registermodal .modal-dialog {
  padding-top: 80px;
}
#registermodal .modal-header {
  margin: 0 auto;
}
#registermodalLabel {
  text-align: center;
}

/*Register And Login Button Style*/

.nav-link:hover {
  background-color: #ed2024 !important;
  color: #fff !important;
}

#outer {
  width: 100%;
  text-align: center;
}
.inner {
  display: inline-block;
}
.wrapper1 {
  background: url('./assets/img/athelete.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: static;
  min-height: 690px !important;
  margin-top: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-dialog2 {
  max-width: 80% !important;
  margin: 1.75rem auto;
  position: relative;
  pointer-events: none;
}

/* .modal-content .close{
  display: none;
} */

/* register.css */
.modal-open {
  overflow: visible !important;
  padding-right: 0px !important;
}
/* .col-md-4{
  flex: 0 0 50% !important;
  max-width: 50% !important;
} */
.from-group1 {
  max-width: 16.6% !important;
  margin-bottom: 20px;
}
.btn1 button {
  background-color: #000;
  color: #ffffff;
  font-weight: 400;
  font-size: 0.8571em;
  line-height: 1.35em;
  border: none;
  margin: 10px 1px;
  border-radius: 0.1875rem;
  padding: 11px 22px;
  margin-left: 43px;
}
.form-group_1 {
  display: flex;
}
.ischeck {
  margin-right: 10px;
}

/* homeloinbtn */
.nav-link1 {
  border: none;
  background-color: #fff;
}
.modal-dialog1 {
  width: 100% !important;
}
.from-group label {
  width: 104px !important;
}

.cbo .ant-select {
  width: 100% !important;
}

.wrapper .section-image:after {
  background-color: none !important;
}

.fgtpwd {
  background: none;
  border: none;
}

.errorMsg {
  color: red;
}

/* .card .card-body {
  padding: 0px 15px 10px 15px !important;
} */

button {
  outline: none !important;
}
@media print {
  .page-break {
    page-break-before: always;
  }
}
