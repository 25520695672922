.sidebar .sidebar-wrapper {
  background-color: white !important;
}
.sidebar .nav li > a {
  color: black !important;
}
.sidebar .nav li:hover:not(.active) > a,
i {
  color: white;
}
.sidebar li {
  background: white !important ;
}
.sidebar[data-color='red']:after,
.off-canvas-sidebar[data-color='red']:after {
  background: #ffffff !important;
}
.sidebar .nav li.active > a:not([data-toggle='collapse']),
.off-canvas-sidebar .nav li.active > a:not([data-toggle='collapse']) {
  background-color: #ff3636;
  margin-right: 40px !important;
}
.now-ui-icons {
  color: black !important;
}
.logo {
  background-color: white !important;
}
.sidenavimg {
  margin-left: 40px;
  padding: 10px !important;
}
.now-ui-icons media-2_sound-wave {
  color: #ff3636 !important;
}
.panel-header {
  background: #1e3149 !important ;
}
.main-panel > .content {
  width: 150% !important;
}
i.now-ui-icons {
  color: black !important;
}
#usericon {
  color: white !important;
}
.main-panel {
  overflow: hidden;
  overflow-y: auto;
}

.sidebar .sidebar-wrapper,
.off-canvas-sidebar .sidebar-wrapper {
  overflow: hidden !important ;
}

.bg-primary {
  background-color: #1e3149 !important;
}

#ptag {
  color: white !important;
  cursor: context-menu;
}

.wrapper {
  margin-right: auto;
  margin-left: auto;
}

.content {
  background: none !important;
  width: 150% !important;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
}

.card {
  margin-left: 0;
  margin-right: 0;
}

#my {
  width: 100% !important;
}

input[type='file'] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

#selectlen {
  width: 410px !important;
}

.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.pagination a.active {
  background-color: #4caf50;
  color: white;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

/* #my {
  display: block;
  width: 100%;
}
#my thead {
  display: block;
}
tbody td, thead th {
  width: 12%;
}
#myTable {
  height:220px;
  overflow-y:auto;
  display: block;
} */

#my th {
  padding: 12px 20px;
  border-bottom: 1px solid #dee2e6;
}

/*----------------------------------------------------------------------------*/

/* manager page After login Style */
.sidenavimg {
  margin-left: 23px;
}
.container-fluid {
  padding-top: 15px;
}
.main-panel .headertxt {
  font-family: Montserrat, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  color: #fff;
}
.card {
  border-radius: 0.875rem;
}
.card-header {
  background-color: rgba(105, 145, 204, 0.5) !important;
  border-top-left-radius: 0.875rem !important;
  border-top-right-radius: 0.875rem !important;
  padding: 10px 15px !important;
  border-radius: calc(0.875rem - 1px) calc(0.875em - 1px) 0 0 !important;
}
.card-body .form-group {
  margin-bottom: 0;
}
.card-body .col-xs-5 {
  border: 1px solid #d1d4d7;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  margin: 0 15px;
}
.colgroup {
  display: none;
}
#inputsm {
  width: 15%;
}
.card-body .card-header {
  background: none !important;
  padding: 15px 15px !important;
}
.table-responsive {
  border: 1px solid #d1d4d7;
  border-top: 0;
}
#myTable tr:nth-child(odd),
#myTable1 tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table > thead > tr > th {
  border-right: 1px solid #d1d4d7;
  border-top: 1px solid #d1d4d7;
}
.mytable > thead > tr > th {
  border-top: 1px solid #d1d4d7;
}

.findbttn {
  text-align: center;
  margin-top: 15px;
}
td {
  border-right: 1px solid #d1d4d7;
}
#my .text-right,
#my1 .text-right,
.text-right {
  border-right: 0;
}
table th,
td {
  text-align: center;
}

/*modal Dialog box Style*/
/* .main-panel .modal {
  position: relative !important;
} */
.main-panel .modal-dialog {
  max-width: 66.666667%;
  /* margin: 0 auto; */

  width: 50%;
}
.main-panel .modal-content {
  max-width: 99%;
  width: 100%;
  box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1);
  border-radius: 0.875rem;
}
.modal-dialog .form-group {
  max-width: 1200px;
  width: 100%;
}
.main-panel .modal-dialog .modal-header {
  background-color: rgba(105, 145, 204, 0.5);
  padding-top: 13px;
  padding-bottom: 13px;
  border-top-left-radius: 0.875rem !important;
  border-top-right-radius: 0.875rem !important;
}
.main-panel .modal-dialog center {
  float: right;
  margin-right: 15px;
}
.main-panel .modal-dialog #selectlen,
.main-panel .modal-dialog .ant-select {
  max-width: 1200px;
  width: 100% !important;
}
.main-panel #regmeet .modal-dialog {
  max-width: 95%;
  margin: 0 auto;
  width: 100%;
  float: right;
  margin-bottom: 50px;
}
.main-panel .modal-dialog .ant-input {
  width: 100% !important;
}

/*Edit User Porfile*/
#useredits .modal-header {
  background-color: rgba(105, 145, 204, 0.5);
  padding-top: 18px;
  padding-bottom: 13px;
  border-top-left-radius: 0.875rem !important;
  border-top-right-radius: 0.875rem !important;
}
#useredits center {
  float: right;
}
.card center {
  float: right;
}

/*Admin Page Style*/
#venueclassedit .modal-dialog,
#addevent .modal-dialog,
#addvenueclass .modal-dialog,
#eventedit .modal-dialog {
  max-width: 95%;
}

/*----------------------------------------------*/

/*Responsive Style*/
@media only screen and (max-width: 991px) {
  .headertxt {
    margin-left: 10% !important;
  }
  .container-fluid .navbar-toggle {
    position: absolute;
    left: 6%;
    top: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .main-panel .col-md-8 {
    max-width: 66.67%;
    width: 100%;
  }
}

#editmeets {
  display: none !important;
}

/*Results Page Style*/
form .form-group label {
  font-size: 14px;
  color: #000;
  width: 100%;
}
.savebtn {
  display: block;
  margin: 5px auto;
}

/*Register style*/
#feedback {
  position: fixed;
  left: 26%;
  top: 120px;
}
#feedback .modal-dialog {
  float: unset;
}
#feedback .modal-content {
  display: block;
  margin: 0 auto;
  max-width: 66.667%;
}

.wrapper_1 {
  position: relative !important;
  top: 20px !important;
  height: 100vh !important;
  left: 100px !important;
}
.form-group select {
  border: 1px solid #e3e3e3;
  border-radius: 30px;
  color: #2c2c2c;
  line-height: normal;
  height: auto;
  font-size: 0.8571em;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  box-shadow: none;
}
.form-group select {
  padding: 10px 18px 10px 18px;
  /* margin: 20px; */
  /* width: 40%; */
}
.input_value {
  padding: 10px 18px 10px 18px;
  margin: 20px;
  border-radius: 30px;
  border: 1px solid #e3e3e3;
}
.form-row label {
  width: 100px;
}
/* .form-row input{
width: 40%;;
} */
.text {
  display: flex;
}
.text_1 {
  width: 50%;
}
.btn_1 button {
  background-color: #ff3636;
  color: #ffffff;
}
.boder {
  border-bottom: 1px solid #000;
}
.report label {
  margin-right: 10px;
}
.report button {
  background-color: #ff3636;
  color: #ffffff;
  margin-right: 10px;
}

/*Meet Manager Login Add Venues*/
.chkbx {
  margin-left: 7px;
  margin-right: 7px;
}
.cbox {
  display: none;
  margin-bottom: 12px;
  padding-left: 15px;
  padding-right: 15px;
}
.main-panel .modal-dialog .chbox {
  max-width: 1200px;
  width: 100% !important;
  padding: 10px 18px 10px 18px;
}
.main-panel .btn-grid a {
  color: #fff !important;
}

/*Athelete Register Styles*/
#registertable {
  border-top: 1px solid #ccc;
}
#registertable tbody td {
  width: 20%;
}
#registertable tbody tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.paybtn {
  margin-left: 1%;
  margin-top: 10px;
}
.pyrecipt {
  margin-top: 1%;
}
.enrolbtn {
  margin-top: -45px;
  float: right;
}
#registertable .ant-checkbox-wrapper span {
  color: #000;
}

/*DetailPage Style*/
.detailpage .col-xs-5 {
  display: none;
}
.detailpage .container .form-group {
  width: 100%;
}
.detailpage .container p {
  float: right;
  width: 50%;
}

.detailpage .container label {
  width: 50%;
  font-weight: bold;
}
.detailpage .container {
  width: 28%;
}
.detailpage .container .detailbtn {
  display: flex;
  width: 100%;
  justify-content: center;
}

#registermodal .confirm label {
  width: 100%;
}
.sidebar .team {
  color: #000 !important;
}

/*ManagerVenues*/
.event-modal .ant-select {
  width: 100% !important;
}
.addvenue label,
.editvenue label {
  color: #000;
}

/* meetviews */
.form-control2 {
  margin-left: 10px;
}
.yesno {
  max-width: 11.11%;
}
.wrapper {
  height: 100% !important;
}
.form-group {
  margin-bottom: 10px !important;
}
.form-group.col-md-3 {
  margin: 0 auto;
  margin-bottom: 0px;
}
.formtext1 {
  font-weight: bold;
}
/* /* #root{
  height: 100%;
}
#root > div:first-child{
  height: 100%;
} */
.main-panel {
  height: 100vh;
}

#registermodal .confirm label {
  width: 100%;
}
.sidebar .team {
  color: #000 !important;
}

/*ManagerVenues*/
.event-modal .ant-select {
  width: 100% !important;
}
.addvenue label,
.editvenue label {
  color: #000;
}

/*Pentathlon*/
.sidebar-wrapper .ppoints {
  width: 75%;
}

#sponser,
#local,
#aregis,
#venuee,
#resultp,
#meetview,
#venueesss,
#eventset,
#bib,
#venuessetup,
#homelink {
  display: none;
}
.form-group_01 {
  max-width: 500px;
}

.records {
  display: block;
  /* width: 25%;
  margin: 20px auto;
  padding-top: 20px; */
}
.content_1 {
  top: -25px !important;
}
/* records_1{
  padding-top: 30px;
} */

/* detailpage_css  */
.container_01 {
  display: flex !important;
  width: 87% !important;
}
.form-row_01 {
  width: 100% !important;
  justify-content: space-between;
}
.form-group_01 {
  width: 50% !important;
  float: left !important;
  flex: none !important;
}

/* meettable_css  */
.card-body .form-group {
  margin-bottom: 0 !important;
}
.table {
  margin-bottom: 0 !important;
}
.table-responsive {
  padding-bottom: 0 !important;
}
#myTable tr:nth-child(2n + 1) {
  background-color: rgb(233, 231, 230) !important;
}
.table > tbody > tr {
  position: unset !important;
}

/*Detail page btn*/

.detail_text {
  border: none;
  width: 40%;
  margin-bottom: 20px;
  padding: 6px;
  background: none;
  border-radius: 30px;
}
.detail_txt {
  border: none;
  width: 70%;
  /* margin-bottom: 20px; */
  padding: 6px;
  background: none;
}
.detail_addres {
  width: 50%;
}

#deatils_heading span {
  margin-left: 10px;
  font-weight: 500;
  margin-right: 10px;
}

#deatils_heading p {
  display: flex;
  font-weight: bold;
}

#deatils_heading tr {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: baseline;
}

#details_heading th {
  display: flex;
  justify-content: space-between;
}

#deatils_heading {
  display: flex;
  justify-content: space-evenly;
}

#card {
  background-color: rgba(105, 145, 204, 0.5) !important;
  border-top-left-radius: 0.875rem !important;
  border-top-right-radius: 0.875rem !important;
  padding: 10px 15px !important;
  border-radius: calc(0.875rem - 1px) calc(0.875em - 1px) 0 0 !important;
}
.ant-empty .ant-empty-description {
  display: none;
}

@media screen and (max-height: 750px) {
  .sidebar .sidebar-wrapper {
    overflow: scroll !important;
    overflow-x: hidden !important;
    height: 100% !important;
    scrollbar-width: thin;
  }
}
.border {
  border: 1px solid #d1d4d7 !important;
}
input[type='text']:disabled {
  background: white;
}

#mytable2 thead tr {
  border-top: 1px solid #dee2e6 !important;
}

/* pagination */
.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  float: right;
  background: #dee2e6;
  border-radius: 50px;
  padding: 6px 15px;
}
.pageatl {
  margin: 0px auto;
}
.pagination > .active > a {
  /* background-color: #47ccde; */
  background-color: #4a84de;
  border-color: #47ccde;
  color: #fff;
}
.pagination > li > a {
  /* border: 1px solid #47ccde; */
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  /* background-color: #47ccde; */
  background-color: #4a84de;
  border-color: #47ccde;
  outline: none;
  border-radius: 50%;
}
/* .pagination > li > a,
.pagination > li > span {
  color: #47ccde;
} */
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}
.pagination a:hover:not(.active) {
  /* background-color: #ddd; */
  background-color: #4a84de;
  border-radius: 50%;
  color: #fff;
}

.card label {
  color: #000;
}

.perrecord {
  border: 1px solid #ccc;
  padding: 10px;
}
.perrecord .form-row {
  justify-content: center;
}

.perrecorddata {
  margin-top: 15px;
  margin-bottom: 15px;
}

/*Venue setup style*/
.venue_btn {
  border: 1px solid #000;
  background: transparent;
  width: 170px;
  height: 30px;
  margin-bottom: 5px;
}
.tt {
  border: 1px solid #000;
  background: transparent;
  width: 230px;
  height: 30px;
  margin-bottom: 5px;
}
#venue_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
}
#inline_view {
  display: flex;
}
.selected_evt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.worksheet_table {
  width: 100%;
}
.worksheet_tr {
  border: 1px solid #eae4e4;
  border-left: 1px solid #eae4e4;
  margin: 10 15px;
}
.worksheet_tr th,
.worksheet_tr td {
  font-size: 12px;
}
.worksheet_tr th {
  border-right: 1px solid #eae4e4;
}

/*Track venuesetup*/
.worksheet_table th {
  padding-top: 10px;
  padding-bottom: 10px;
}

/*Relay setup*/
.realy_main {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  border-bottom: 1px double #000;
  margin-top: 8px;
  border-radius: 15px;
}
.relay_label {
  font-size: 12px;
  font-weight: 700;
  padding-right: 10px;
}
.realy_main1 p {
  text-align: center;
  margin: 0;
  margin-bottom: 5px;
}

.total_points {
  padding-top: 5px;
  border-top: 1px solid #000;
}

/*bib Assign*/
.bib .yesno {
  max-width: 15%;
}

.bibassign input[type='radio'] {
  margin-left: 10px;
  margin-right: 10px;
}
#myTable .points {
  text-align: center;
}
/* .bibassign label{

  margin-left: 10px;
}

.bibassign input[type='radio'],input[type='text'] {
  margin-right: 10px;
  margin-left: 10px;
} */

/* .athletebtn {
    display: inline-block;
    float: right;
    width: 58% !important;
} */
.back {
  display: inline-block;
  margin-top: 28px !important;
}

.content_1 h2 {
  margin-bottom: 0;
  margin-top: 20px;
}

.form-group input[type='file'] {
  opacity: 1;
  position: unset;
}

.records {
  display: flex;
  float: left;
  margin-left: 20%;
  justify-content: center;
  align-items: baseline;
  position: relative;
  top: -15px;
  width: 50%;
}

#inputsm {
  float: left;
  margin-bottom: 15px;
}
.record-main {
  display: flex;
  flex-direction: row;
}
.export-btn {
  display: flex;
  flex-flow: row-reverse;
  width: 100%;
  position: relative;
  top: -15px;
}

.records .btn {
  margin-left: 25px;
}

.navbar .collapse {
  align-items: center;
  flex-grow: 0;
}

.titleclass h2 {
  color: #fff;
  font-size: 30px;
  /*font-weight: 400;*/
  margin-bottom: 0;
}

.headertxt .titleclass h2 {
  display: none;
}

.main-panel .navbar .navbar-wrapper {
  display: none;
}

@media only screen and (max-width: 992px) {
  .main-panel .navbar .navbar-wrapper {
    display: block;
  }
}

.chcc {
  margin-top: 15px;
}

.regisbtn {
  text-align: center;
}
/* .svg-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.svg-wrapper svg {
  width: 100% !important;
} */
.svg-wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  padding: 30px;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.svg-wrapper p {
  font-size: medium;
}

/* .App .rowx {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.App .rowx > .item {
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
} */

.navbar-nav .nav-link {
  cursor: pointer;
}

.header-row td {
  border-right: 0;
}

.header-row th p {
  margin-left: 17.5px;
  margin-right: 17.5px;
}

.arcassign .relay_label1 {
  margin-left: 15px;
}

.radiobib input {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 6px;
}

.trans {
  display: flex;
  justify-content: space-around;
}
.radiobib {
  display: flex;
  justify-content: center;
}

.printrow center {
  float: none;
}

.printbtn {
  background: none;
  color: #000;
  box-shadow: 2px 2px 8px 1px #c2bcbc;
}

.printbtn:hover {
  box-shadow: none;
  background: blue;
  color: #fff;
}

.printrow .btn:not([data-action]):not([class*='btn-outline-']):hover {
  box-shadow: none;
}

.printrow .btn-success:not(:disabled):not(.disabled):active {
  background: blue;
}

.printrow .btn-success:hover {
  color: none;

  background: blue;
  color: #fff;
}

.printrow .btn-success:focus {
  box-shadow: 2px 2px 8px 1px #c2bcbc;
  background: none;
  color: #000;
}

/* .printrow {
  display: block;
} */

.printrow1 {
  display: flex;
  justify-content: center;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 12px 5px;
}

@media only screen and (max-width: 1280px) {
  .form-group .findbttn {
    display: flex !important;
  }
}

@media only screen and (max-width: 1550px) {
  .scrollcard .card-body {
    overflow-x: scroll;
  }

  .scrollcard1 {
    overflow-x: scroll;
  }
}

/**/
.form-committe .form-group label {
  width: 125px;
}

.athletefootertxt {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  margin-top: 12px;
}

.athletefootertxtstar {
  color: red;
}

.athlete_tracktb th {
  display: block !important;
}

.card .wtable {
  border: none;
}

.boxmodel ul {
  margin: 0;
}
/* .boxmodel {

  margin-top: 20px;

}  */

.boxmodel ul li {
  border: 1px solid black;
  padding: 20px;
  width: 75px;
}

.boxmodel ul {
  display: flex;
  list-style: none;
}
.arcscoresheet {
  display: inline;
  align-items: center;
  /* border-bottom: 2px solid #000; */
  width: 100%;
  /* margin-top: 10%; */
  /* float: left; */
}

.ovclass {
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 7px;
}

.ovprintbtn {
  margin-left: 20px;
}

.aligncenter {
  display: flex;
  justify-content: space-evenly;
}
.checkalign {
  display: flex;
  justify-content: center;
}

/*athlete Search*/
#inputsms {
  width: 15%;
  height: 38px;
  margin-bottom: 15px;
  float: left;
}

.card-body .col-xs-6 {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  margin: 0 1px;
}
.attpagenation {
  margin: 0px;
  z-index: 2;
  float: right;
  position: relative;
}

.records1 {
  margin-left: 6%;
}

.form-group .records1 input[type='file'] {
  position: unset;
  width: 50%;
}

.xx .form-control1 {
  margin-left: 15px;
  margin-right: 15px;
  /* margin-top: 5px; */
}
.chkbx1 {
  margin-left: 17px;
  margin-right: 7px;
}
.radio-flex {
  display: flex;
}
.form1-group1 {
  display: flex;
}
.clrgreen {
  background-color: #00b300;
  color: white;
}
.clrred {
  background-color: #ff3333;
  color: white;
}
.clrwhite {
  background-color: white;
}

/*New event sponsor and local committe Style*/
#newsponser label,
#sponseredit label {
  width: 120px;
}
#comiteedit label,
#newcomitte label {
  width: 120px;
}

/*Venue Setup Design*/
.venuesetup .content {
  width: 100% !important;
}
.venuesetup .table-responsive {
  overflow: hidden;
}
.sidebar .sidebar-wrapper {
  height: 100vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  padding-bottom: 50px;
}
